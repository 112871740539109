import { useState, useEffect } from "react";
import { Table, Button} from "react-bootstrap"
import { getVanillaAshes, getVanillaAshesCollection } from "../lib/flow/vanillaAshes";
import ReactPaginate from "react-paginate"

export const VanillaAshesTable = (props) => {
  const [user, setUser] = useState({ loggedIn: null })

  const [nftIDs, setNftIDs] = useState([])
  const [nfts, setNFTs] = useState([])
  const [currentNFTIDs, setCurrentNFTIDs] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const pageSize = 8

  useEffect(() => {
    if (props.user !== undefined) {
      setUser(props.user)
    }
  }, [props.user])

  useEffect(() => {

    if (nftIDs.length === 0 ) {
      setCurrentNFTIDs([])
      return
    }

    setCurrentNFTIDs(nftIDs.slice(0, pageSize))
  }, [nftIDs])

  useEffect(() => {
    if (!user.loggedIn) {
      setNftIDs([])
      setTotalCount(0)
      return
    }

    try {
      getVanillaAshesCollection(user.addr)
      .then((collection) => {
        console.log(collection);
        if (collection) {
          setNftIDs(collection.nftIDs)
          setTotalCount(collection.nftIDs.length)
        }
      })
    } catch(error) {
      console.log(error)
    }
  }, [user, props.onReload])

  useEffect(() => {

    if (!user.loggedIn || currentNFTIDs.length === 0) {
      setNFTs([])
      return
    }
    try {
      getVanillaAshes(user.addr, currentNFTIDs)
      .then((nfts) => {
        console.log(nfts)
        setNFTs(nfts)
      })
    } catch(error) {
      console.log(error)
    }
  }, [user, currentNFTIDs])

  const tablePageClick = (data) => {
    console.log("click")
    let currentNFTIDs = nftIDs.slice(data.selected * pageSize, data.selected * pageSize + pageSize)
    setCurrentNFTIDs(currentNFTIDs)
  }


  const onBurnClick = (nftID) => () => {
    props.onBurnClick && props.onBurnClick(nftID)
  }

  return (
    <div>
      <h3>
        <p>Vanilla Ashes:  {totalCount}</p>
      </h3>
      {nfts && !!nfts.length && (
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>ash id</th>
                <th>ash serial</th>
                <th>set name</th>
                <th>play ID</th>
                <th>palyer full name</th>
                <th>serial number</th>
                <th>burn</th>
              </tr>
            </thead>
            <tbody>
              {nfts
                .sort((a, b) => {
                  return a.setName === b.setName ? 0 : +(a.setName > b.setName) || -1
                })
                .map((nft) => {
                  return (
                    <tr key={nft.id}>
                      <td>{nft.id}</td>
                      <td>{nft.ashSerial}</td>
                      <td>{nft.setName}</td>
                      <td>{nft.momentData.playID}</td>
                      <td>{nft.play.FullName}</td>
                      <td>{nft.momentData.serialNumber}</td>
                      <td><Button variant="danger" onClick={onBurnClick(nft.id)}>burn</Button></td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </div>
      )}

      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={<span className="page-link">...</span>}
        breakClassName={"page-item"}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        pageCount={totalCount / pageSize}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={tablePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />

    </div>
  )

}