import { useState, useEffect } from "react";
import { getAshesSummary } from "../lib/flow/vanillaAshes"

export const AshesV1Summary = (props) => {
    const [ashesSummary, setAshesSummary] = useState({ latestBroadcast: {}, allowMint: false })

    useEffect(() => {
        getAshesSummary().then(
            (ashesSummary) => {
                console.log(ashesSummary)
                setAshesSummary(ashesSummary)
            }
        )
    }, [props.onReload])

    return (
        <div>
            {ashesSummary.latestBroadcast &&
                <div>
                    <h4>{ashesSummary.latestBroadcast.subject}</h4>
                    <p>{ashesSummary.latestBroadcast.payload}</p>
                </div>
            }
            {ashesSummary.allowMint ? "minting is open" : "minting is closed"}
            <p>
                next Ash serial: {ashesSummary.nextAshSerial}
            </p>
        </div>
    )
}