import { useState } from "react";
import { burnNFLToAshesV2X } from "../lib/flow/nfl"
import { Row, Col } from "react-bootstrap"
import { AshesTable } from "../components/ashes"
import { TXMonitor } from "../components/txmonitor"
import { NFLTable } from "../components/nfl"

export const NFL = () => {

  const [transactionId, setTransactionId] = useState(null)
  const [reloadCollection, setReloadCollection] = useState(false)


  const onBurnClick = (nftID) => {
    console.log(nftID)
    try {
      burnNFLToAshesV2X(nftID, setTransactionId)
    } catch (error) {
      console.log(error)
    }

  }


  return (
    <div>
      <Row>
        <TXMonitor txID={transactionId} onSuccess={setReloadCollection}></TXMonitor>

      </Row>

      <Row>
        <Col>
          <AshesTable onReload={reloadCollection}></AshesTable>
        </Col>
        <Col>
          <NFLTable onReload={reloadCollection} onBurnClick={onBurnClick}></NFLTable>
        </Col>
      </Row>

    </div>
  )
}