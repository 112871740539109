import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";

export const getNFLCollection = async (address) => {
  const resp = await fcl.send([
    fcl.script(getNFLCollectionScript), fcl.args([fcl.arg(address, t.Address)])
  ],
  )
  return fcl.decode(resp)
}

export const getNFLNFTs = async (address, nftIDs) => {
  if (!nftIDs || (nftIDs && nftIDs.length === 0)) {
    return []
  }

  const resp = await fcl.send([
    fcl.script(getNFLNFTScript),
    fcl.args([fcl.arg(nftIDs, t.Array(t.UInt64)), fcl.arg(address, t.Address)]),
  ])
  return fcl.decode(resp)

}

export const burnNFLToAshesV2X = async (nflID, setTransactionId) => {
  const transactionId = await fcl.mutate({
    cadence: burnNFLToAshes2Script,
    args: (arg, t) => [
      arg([nflID], t.Array(t.UInt64)),
      arg("derp", t.String)
    ],
    payer: fcl.authz,
    proposer: fcl.authz,
    authorizations: [fcl.authz],
    limit: 800
  })
  setTransactionId(transactionId)
}

const burnNFLToAshes2Script = `import AshesV2 from 0xASHESV2_ADDRESS
import AllDay from 0xALLDAY_ADDRESS

transaction(nflIDs: [UInt64], message: String) {
  let allDayCollection: &AllDay.Collection
  let ashesCollection: &AshesV2.Collection

  prepare(acct: AuthAccount) {
    self.allDayCollection = acct.borrow<&AllDay.Collection>(from: AllDay.CollectionStoragePath)!

    if acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath) == nil {
      let collection <- AshesV2.createEmptyCollection()
      acct.save(<-collection, to: AshesV2.CollectionStoragePath)
      acct.link<&{AshesV2.AshReceiver}>(AshesV2.CollectionPublicPath, target: AshesV2.CollectionStoragePath)
    }
    self.ashesCollection = acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath)!
  }

  execute {
      for id in nflIDs {
          let nflNFT <- self.allDayCollection.withdraw(withdrawID: id) as! @AllDay.NFT
          self.ashesCollection.deposit(token: <- AshesV2.mintFromNFLAllDay(alldayNFT: <- nflNFT, msg: message))
      }
  }
}`

const getNFLNFTScript = `
      import AllDay from 0xALLDAY_ADDRESS
      pub struct allDayNFT {
        pub let id: UInt64
        pub let editionID: UInt64
        pub let serialNumber: UInt64
        pub let mintingDate: UFix64
  
        pub let editionData: AllDay.EditionData
        pub let playData: AllDay.PlayData
        pub let setData: AllDay.SetData
        pub let seriesData: AllDay.SeriesData
      
        init(nft: &AllDay.NFT) {
          self.id = nft.id
  
          self.serialNumber = nft.serialNumber
          self.mintingDate = nft.mintingDate
  
          self.editionID = nft.editionID
          self.editionData = AllDay.getEditionData(id: self.editionID)
  
          self.playData = AllDay.getPlayData(id: self.editionData.playID)
          
          self.setData = AllDay.getSetData(id: self.editionData.setID)
  
          self.seriesData = AllDay.getSeriesData(id: self.editionData.seriesID)
  
        }
      }
      pub fun main(nftIDs: [UInt64], acctAddr: Address): [allDayNFT] {
      let acct = getAccount(acctAddr)
      let collectionRef = acct.getCapability(AllDay.CollectionPublicPath)!
                    .borrow<&{AllDay.MomentNFTCollectionPublic}>()!
      var nfts: [allDayNFT] = []
      for nftID in nftIDs {
        let nft = collectionRef.borrowMomentNFT(id: nftID)!
        if nft != nil{
          nfts.append(allDayNFT(nft: nft))
        }
      }
      return nfts
    }  `

const getNFLCollectionScript = `
import AllDay from 0xALLDAY_ADDRESS
import AshesV2 from 0xASHESV2_ADDRESS

pub struct AllDayCollection {
  pub var nftIDs: [UInt64]
  pub let ashesIDs: [UInt64]

  init(nftIDs: [UInt64], ashesIDs: [UInt64]) {
    self.nftIDs = nftIDs
    self.ashesIDs = ashesIDs
  }
}
pub fun main(acctAddr: Address): AllDayCollection {
  let acct = getAccount(acctAddr)
  var nftIDs: [UInt64] = []
  if let collectionRef = acct.getCapability(AllDay.CollectionPublicPath).borrow<&{AllDay.MomentNFTCollectionPublic}>(){
    nftIDs = collectionRef.getIDs()
  }

  var ashesIDs: [UInt64] = []
  if let ashesReceiver = acct.getCapability(/public/AshesV2Collection).borrow<&{AshesV2.AshReceiver}>() {
    ashesIDs = ashesReceiver.getIDs()
  }

    
  return AllDayCollection(nftIDs: nftIDs, ashesIDs: ashesIDs)
}`