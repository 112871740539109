export const Releases = () => {
    return (
        <div>
            <ul>
            <li>
                    <b>v0.1.6 2022-10-17</b><br />
                    <ul>
                        <li>enabled support for NFL and UFC</li>
                    </ul>
                </li>

            <li>
                    <b>v0.1.5 2022-07-18</b><br />
                    <ul>
                        <li>upping gas limit to 9999</li>
                    </ul>
                </li>

            <li>
                    <b>v0.1.4 2022-07-02</b><br />
                    <ul>
                        <li>Added select all for Topshot batch burning</li>
                    </ul>
                </li>
                <li>
                    <b>v0.1.3 2022-06-15</b><br />
                    <ul>
                        <li>Secure cadence compatibility</li>
                        <li>Recent burns default to 20 per page</li>
                    </ul>
                </li>
                <li>
                    <b>v0.1.2 2022-06-05</b><br />
                    <ul>
                        <li>Batch minting for NBA Topshot</li>
                        <li>Releases Page</li>
                    </ul>
                </li>
                <li>
                    <b>v0.1.1 2022-06-05</b><br />
                    <ul>
                        <li>AshesV2 contract which added extensibility support</li>
                        <li>RecentBurn + messages</li>
                        <li>VanillaAsh Burn to AshesV2</li>
                    </ul>
                </li>
                <li>
                    <b>v0.1.0 2022-04-03</b><br />
                    Initial Release
                </li>

            </ul>

        </div>
    )
}