import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types"

export const getNBACollection = async (address) => {
  const resp = await fcl.send([
    fcl.script(getNBACollectionScript), fcl.args([fcl.arg(address, t.Address)])
  ])
  return fcl.decode(resp)

}

export const getNBANFTs = async (address, nftIDs) => {
  if (!nftIDs || (nftIDs && nftIDs.length === 0)) {
    return []
  }
  const resp = await fcl.send([
    fcl.script(getNBANFTSScript),
    fcl.args([fcl.arg(nftIDs, t.Array(t.UInt64)), fcl.arg(address, t.Address)]),
  ])
  return fcl.decode(resp)
}

export const getNBANFTsFromMeta = async(metaDataList) => {
  console.log(metaDataList)

  if (!metaDataList) {
    return []
  }
  const resp = await fcl.send([
    fcl.script(getNBATSFromMetaScript),
    fcl.args([
      fcl.arg(metaDataList.idList, t.Array(t.UInt64)), 
    fcl.arg(metaDataList.playIDList, t.Array(t.UInt32)),
    fcl.arg(metaDataList.setIDList, t.Array(t.UInt32)),
    fcl.arg(metaDataList.serialNumberList, t.Array(t.UInt32)),
    fcl.arg(metaDataList.ashSerialList, t.Array(t.UInt64))
  ]),
  ])
  return fcl.decode(resp)

}

export const burnNBAoAshesV2X = async (burnIDs, burnMessage, setTransactionId) => {

  let txID = await fcl.mutate({
    cadence: burnTopShotToAshesScript,
    args: (arg, t) => [
      arg(burnIDs, t.Array(t.UInt64)),
      arg(burnMessage, t.String)
    ],
    payer: fcl.authz,
    proposer: fcl.authz,
    authorizations: [fcl.authz],
    limit: 9999
  })
  setTransactionId(txID)
}

const getNBACollectionScript = `
import TopShot from 0xTOPSHOT_ADDRESS

pub struct TopShotCollection {
    pub var nftIDs: [UInt64]

    init(nftIDs: [UInt64]) {
        self.nftIDs = nftIDs
      }
}

pub fun main(acctAddr: Address): TopShotCollection {
  let acct = getAccount(acctAddr)
  var nftIDs: [UInt64] = []
  if let collectionRef = acct.getCapability(/public/MomentCollection).borrow<&{TopShot.MomentCollectionPublic}>(){
    nftIDs = collectionRef.getIDs()
}

  
  return TopShotCollection(nftIDs: nftIDs)
}`

const getNBANFTSScript = `
import TopShot from 0xTOPSHOT_ADDRESS
pub struct Moment {
  pub var id: UInt64?
  pub var playId: UInt32?
  pub var meta: TopShot.MomentData?
  pub var play: {String: String}?
  pub var setId: UInt32?
  pub var setName: String?
  pub var serialNumber: UInt32?
  init(_ moment: &TopShot.NFT?) {
    self.id = moment?.id
    self.meta = moment?.data
    self.playId = moment?.data?.playID
    self.play = nil
    self.play = TopShot.getPlayMetaData(playID: self.playId!)
    self.setId = moment?.data?.setID
    self.setName = nil
    self.setName = TopShot.getSetName(setID: self.setId!)
    self.serialNumber = nil
    self.serialNumber = moment?.data?.serialNumber
  }
}
pub fun main(momentIDs: [UInt64], acctAddr: Address): [Moment] {
let acct = getAccount(acctAddr)
let collectionRef = acct.getCapability(/public/MomentCollection)!
              .borrow<&{TopShot.MomentCollectionPublic}>()!
  var moments: [Moment] = []
  for momentID in momentIDs {
    let moment = collectionRef.borrowMoment(id: momentID)
    if moment != nil{
      moments.append(Moment(moment))
    }
  }
  return moments
}  `

const getNBATSFromMetaScript = `
import TopShot from 0xTOPSHOT_ADDRESS
pub struct Moment {
  pub var id: UInt64?
  pub var playId: UInt32?
  pub var play: {String: String}?
  pub var setId: UInt32?
  pub var setName: String?
  pub var serialNumber: UInt32?
  pub var ashSerial: UInt64?
  init(id: UInt64, playID: UInt32, setID: UInt32, serialNumber: UInt32, ashSerial: UInt64) {
    self.id = id
    self.playId = playID
    self.play = nil
    self.play = TopShot.getPlayMetaData(playID: self.playId!)
    self.setId = setID
    self.setName = nil
    self.setName = TopShot.getSetName(setID: self.setId!)
    self.serialNumber = serialNumber
    self.ashSerial = ashSerial
  }
}
pub fun main(id: [UInt64], playID: [UInt32], setID: [UInt32], serialNumber: [UInt32], ashSerial: [UInt64]): [Moment] {
  var moments: [Moment] = []

  var i = 0;
  while i < id.length {
    moments.append(Moment(id: id[i],playID: playID[i],setID: setID[i],serialNumber: serialNumber[i], ashSerial: ashSerial[i]))
    i = i + 1
  }
  return moments
} 
`

const burnTopShotToAshesScript = `import TopShot from 0xTOPSHOT_ADDRESS
      import AshesV2 from 0xASHESV2_ADDRESS
    
      transaction(nbaIDs: [UInt64], message: String) {
        let collectionRef: &TopShot.Collection
        let ashesCollection: &AshesV2.Collection
  
        prepare(acct: AuthAccount) {
          self.collectionRef = acct.borrow<&TopShot.Collection>(from: /storage/MomentCollection)!
          
          if acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath) == nil {
            let collection <- AshesV2.createEmptyCollection()
            acct.save(<-collection, to: AshesV2.CollectionStoragePath)
            acct.link<&{AshesV2.AshReceiver}>(AshesV2.CollectionPublicPath, target: AshesV2.CollectionStoragePath)
          } 
          self.ashesCollection = acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath)!
        }
  
        execute {
            for id in nbaIDs {
                let token <- self.collectionRef.withdraw(withdrawID: id) as! @TopShot.NFT
                self.ashesCollection.deposit(token: <- AshesV2.mintFromTopShot(topshotNFT: <- token, msg: message))
            }
  
        }
      }`
