import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { getNFLCollection, getNFLNFTs } from "../lib/flow/nfl"
import { Table, Button } from "react-bootstrap"
import ReactPaginate from "react-paginate"

export const NFLTable = (props) => {
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser.subscribe(setUser), []);

  const [nftIDs, setNftIDs] = useState([])
  const [nfts, setNFTs] = useState([])
  const [currentNFTIDs, setCurrentNFTIDs] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const pageSize = 8

  useEffect(() => {
    setCurrentNFTIDs(nftIDs.slice(0, pageSize))
  }, [nftIDs])


  useEffect(() => {
    if (!user.addr) {
      return
    }

    getNFLCollection(user.addr)
      .then((collection) => {
        console.log(collection);
        if (collection) {
          setNftIDs(collection.nftIDs)
          setTotalCount(collection.nftIDs.length)
        }
      })
  }, [user, props.onReload])

  useEffect(() => {
    if (!user.addr) {
      return
    }

    getNFLNFTs(user.addr, currentNFTIDs)
      .then((nfts) => {
        console.log(nfts)
        setNFTs(nfts)
      })
  }, [user, currentNFTIDs])

  const tablePageClick = (data) => {
    let currentNFTIDs = nftIDs.slice(data.selected * pageSize, data.selected * pageSize + pageSize)
    setCurrentNFTIDs(currentNFTIDs)
  }


  const onBurnClick = (nftID) => () => {
    props.onBurnClick && props.onBurnClick(nftID)
  }

  return (
    <div>
      <h3>
        <p>NFL AllDay NFTs: {nftIDs && nftIDs.length}</p>
      </h3>
      {nfts && nfts.length && (
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>id</th>
                <th>set name</th>
                <th>edition ID</th>
                <th>serial number</th>
                <th>edition tier</th>
                <th>player</th>

                {/* <th>preview</th> */}
                <th>burn</th>
              </tr>
            </thead>
            <tbody>
              {nfts
                .map((nft) => {
                  return (
                    <tr key={nft.id}>
                      <td>{nft.id}</td>
                      <td>{nft.setData.name}</td>
                      <td>{nft.editionID}</td>
                      <td>{nft.serialNumber}</td>
                      <td>{nft.editionData.tier}</td>
                      <td>{nft.playData.metadata.playerFirstName} {nft.playData.metadata.playerLastName}</td>
                      {/* <td><img width="128px" height="128px" src={nft.setMeta.preview}></img></td> */}
                      <td><Button variant="danger" onClick={onBurnClick(nft.id)}>burn</Button></td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>

          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={<span className="page-link">...</span>}
            breakClassName={"page-item"}
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            pageCount={Math.ceil(totalCount / pageSize)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={tablePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />

        </div>
      )}

    </div>
  )

}
