import { useState, useEffect } from "react"
import { getAshesV2Summary, getRecentBurns } from "../lib/flow/ashes"
import { Table } from "react-bootstrap"
import { metaToDescription } from "./ashes"
import ReactPaginate from "react-paginate"
import { getNBANFTsFromMeta } from "../lib/flow/nba"

export const RecentBurnsTable = (props) => {
  const [recentBurns, setRecentBurns] = useState([])
  const [annotatedRecentBurns, setAnnotatedRecentBurns] = useState([])
  const [currentPageStart, setCurrentPageStart] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [ashesSummary, setAshesSummary] = useState({ nextAshSerial: 0, allowMint: false })
  const pageSize = 20
  const censoredSerials = []
  useEffect(() => {
    getAshesV2Summary().then((ashesSummary) => {
      console.log(ashesSummary)
      setAshesSummary(ashesSummary)
      setTotalCount(ashesSummary.nextAshSerial-1)
    })
  }, [])

  useEffect(() => {
    if (!recentBurns || recentBurns.length === 0) {
      return
    }

    let topshotBurns = recentBurns.filter(x=>x.ashData && (x.ashData.nftType.typeID==="A.0b2a3299cc857e29.TopShot.NFT" || x.ashData.nftType.typeID==="A.e2a89587100c6096.Ashes.Ash"))
    getNBANFTsFromMeta({
      idList: topshotBurns.map(x=>parseInt(x.ashData.meta["topshotID"] ? x.ashData.meta["topshotID"]: x.ashData.meta["vanillaAshTopshotID"])),
      playIDList: topshotBurns.map(x=>parseInt(x.ashData.meta["topshotPlayID"] ? x.ashData.meta["topshotPlayID"] : x.ashData.meta["vanillaAshTopshotPlayID"])),
      setIDList: topshotBurns.map(x=>parseInt(x.ashData.meta["topshotSetID"] ? x.ashData.meta["topshotSetID"] : x.ashData.meta["vanillaAshTopshotSetID"])),
      serialNumberList: topshotBurns.map(x=>parseInt(x.ashData.meta["topshotSerial"] ? x.ashData.meta["topshotSerial"]:x.ashData.meta["vanillaAshTopshotSerial"] )),
      ashSerialList: topshotBurns.map(x=>x.serial)
    }).then((x) => {
      let annotatedRecentBurns = recentBurns.map(b => {
        if (!b.ashData || !b.ashData.nftType === "A.0b2a3299cc857e29.TopShot.NFT" || !b.ashData.nftType === "A.e2a89587100c6096.Ashes.Ash") {
          return b
        }
        let tsMeta = x.find(t=>t.ashSerial===b.serial)
        b.ashData.meta.tsData = tsMeta
        return b
      })
      console.log(annotatedRecentBurns)
      setAnnotatedRecentBurns(annotatedRecentBurns)
    })


  }, [recentBurns])


  useEffect(() => {
    try {
      getRecentBurns(currentPageStart+1, currentPageStart+1 + pageSize)
      .then((recentBurns) => {
        console.log(recentBurns);
        setRecentBurns(recentBurns);
      })
    } catch(error) {
      console.log(error)
    }
  }, [currentPageStart, pageSize])

  const tablePageClick = (data) => {
    setCurrentPageStart(data.selected * pageSize)
  }


  return (
    <div>
      <h3>
        <p>Recent Burns:</p>
        <p>Ashes Count: {ashesSummary.nextAshSerial}</p>
        <p>can mint: {ashesSummary.allowMint ? "yes" : "no"}</p>
      </h3>

      <Table striped bordered responsive="md" hover size="sm">
        <thead>
          <tr>
            <th>ashSerial</th>
            <th>burned NFT type</th>
            <th>message</th>
            <th>metadata</th>
          </tr>
        </thead>
        <tbody>
          {recentBurns && recentBurns
            .map((burn) => {
              if (burn.ashData) {
                return (
                  <tr key={burn.serial}>
                    <td>{burn.ashData.ashSerial}</td>
                    <td>{burn.ashData.nftType.typeID}</td>
                    <td style={{wordBreak: 'break-all', maxWidth: '500px', background: censoredSerials.includes(burn.ashData.ashSerial) && 'black'}}>{burn.ashData.meta["_message"]}</td>
                    <td style={{wordBreak: 'break-all'}} >{metaToDescription(burn.ashData.nftType, burn.ashData.meta)}</td>
                  </tr>
                )
              } else {
                return (
                  <tr key={burn.serial}>
                    <td>{burn.serial}</td>
                    <td>vanilla ash</td>
                    <td style={{wordBreak: 'break-all', maxWidth: '500px'}}></td>
                    <td></td>
                  </tr>
                )

              }
            })}
        </tbody>
      </Table>

      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={<span className="page-link">...</span>}
        breakClassName={"page-item"}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        pageCount={Math.ceil(totalCount / pageSize)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={tablePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />

    </div>
  )

}