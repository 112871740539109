import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";

export const getAsh2s = async (address, ash2IDs) => {
    if (!ash2IDs || (ash2IDs && ash2IDs.length === 0)) {
      return []
    }
    const resp = await fcl.send([
      fcl.script(getAsh2sScript),
      fcl.args([fcl.arg(ash2IDs, t.Array(t.UInt64)), fcl.arg(address, t.Address)]),
    ])
    return fcl.decode(resp)
  }

  export const getAshesV2Summary = async () => {
    try {
      const resp = await fcl.send([
        fcl.script(getAshesV2SummaryScript),
      ])
      return fcl.decode(resp)
    } catch (e) {
      console.log(e);
  }
  
  }
  
  export const getRecentBurns = async (start, end) => {
    try {
        const resp = await fcl.send([
          fcl.script(getRecentBurnsScript), fcl.args([fcl.arg(start, t.Int), fcl.arg(end, t.Int)])
        ])
        return fcl.decode(resp)
      } catch (e) {
      console.log(e);
    }
  
  }

  export const getAshesCollection = async (address) => {
    try {
      const resp = await fcl.send([
        fcl.script(getAshesCollectionScript), fcl.args([fcl.arg(address, t.Address)])
      ])
      return fcl.decode(resp)
    } catch (e) {
    console.log(e);
  }
  }
  
  const getAshesCollectionScript = `
  import AshesV2 from 0xASHESV2_ADDRESS

  pub struct AshesCollection {
    pub let ashesIDs: [UInt64]

    init(ashesIDs: [UInt64]) {
      self.ashesIDs = ashesIDs
    }
  }
  pub fun main(acctAddr: Address): AshesCollection {
    let acct = getAccount(acctAddr)

    var ashesIDs: [UInt64] = []
    if let ashesReceiver = acct.getCapability(AshesV2.CollectionPublicPath).borrow<&{AshesV2.AshReceiver}>() {
      ashesIDs = ashesReceiver.getIDs()
    }
      
    return AshesCollection(ashesIDs: ashesIDs)
  }`
  
  const getRecentBurnsScript = `
  import AshesV2 from 0xASHESV2_ADDRESS
  
  pub struct RecentBurn {
    pub let serial: Int
    pub let ashData: AshesV2.AshData?

    init(serial: Int, ashData: AshesV2.AshData?) {
      self.serial = serial
      self.ashData = ashData
    }
  }
  pub fun main(start: Int, end: Int): [RecentBurn] {
    let res: [RecentBurn] = []
    var i = start
    while i < end && i < Int(AshesV2.nextAshSerial){
        res.append( RecentBurn(serial: i, ashData: AshesV2.getRecentBurn(index: i-1)) )
        i = i + 1
    }
    return res
  }`


  const getAsh2sScript = `
  import AshesV2 from 0xASHESV2_ADDRESS  
  pub struct Ash {
    pub let id: UInt64
    pub let ashData: AshesV2.AshData

    init(id: UInt64, ashData: AshesV2.AshData) {
      self.id = id
      self.ashData = ashData
    }
  }
  pub fun main(ashesIDs: [UInt64], acctAddr: Address): [Ash] {
  let acct = getAccount(acctAddr)
  let collectionRef = acct.getCapability(AshesV2.CollectionPublicPath)!.borrow<&{AshesV2.AshReceiver}>()!
  var ashes: [Ash] = []

    for ashID in ashesIDs {
      let ash = collectionRef.borrowAsh(id: ashID)
      if ash != nil{
        ashes.append(Ash(id: ashID, ashData: ash!.data))
      }
    }
    return ashes
  }
`
  

const getAshesV2SummaryScript = `
import AshesV2 from 0xASHESV2_ADDRESS
pub struct AshesSummary {
  pub var nextAshSerial: UInt64
  pub var allowMint: Bool
  init(nextAshSerial: UInt64, allowMint: Bool) {
    self.nextAshSerial = nextAshSerial
    self.allowMint = allowMint
  }
}
pub fun main(): AshesSummary {
  return AshesSummary(nextAshSerial: AshesV2.nextAshSerial, allowMint: AshesV2.allowMint)
}`
