import {Modal, Button } from "react-bootstrap"

export const BurnModal = (props) => {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Burn Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>message (max length 420)</h5>
                <textarea style={{width:"100%"}} maxLength="420" defaultValue={props.burnMessage} onChange={(e) => {
                    props.setBurnMessage(e.target.value)
                }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={props.handleConfirmBurn}>
                    Confirm Burn
                </Button>
            </Modal.Footer>
        </Modal>
    )
}