import { config } from "@onflow/fcl";
import { send as httpSend } from '@onflow/transport-http';

var c = config({
  "accessNode.api": "https://rest-mainnet.onflow.org", // mainnet
  // "accessNode.api": "https://rest-testnet.onflow.org", // Testnet
  // "accessNode.api": "http://localhost:8080", // Local
  // "accessNode.api": "https://access-mainnet-beta.onflow.org", // Mainnet grpc web

  "discovery.wallet": "https://fcl-discovery.onflow.org/authn", // Mainnet blocto
  // "discovery.wallet": "https://accounts.meetdapper.com/fcl/authn-restricted", // Mainnet dapper
  // "discovery.wallet": "https://fcl-discovery.onflow.org/testnet/authn", // Testnet blocto
  // "discovery.wallet": "https://staging.accounts.meetdapper.com/fcl/authn-restricted", // Testnet dapper
  // "discovery.wallet": "http://localhost:8002/fcl/authn?localUrl=https://localhost:4000/", // Local

  "discovery.wallet.method": "POP/RPC", // "HTTP/POST" for back channel

  // These configs are for debugging through the JS console in the browser
  "debug.accounts": false,
  "debug.signatures": false,
  "debug.resolved": false,
  'app.detail.title': 'Ashes',
  'sdk.transport': httpSend
});

// c.put('0xTOPSHOT_ADDRESS', '0x877931736ee77cff');
// c.put('0xALLDAY_ADDRESS', '0x4dfd62c88d1b6462');
// c.put('0xUFCINT_ADDRESS', '0x04625c28593d9408');

// c.put('0xASHESV1_ADDRESS', '0x86dec4853355ef64');
// c.put('0xASHESV2_ADDRESS', '0x0cc02fc2a3edf170');

c.put('0xTOPSHOT_ADDRESS', '0x0b2a3299cc857e29');
c.put('0xALLDAY_ADDRESS', '0xe4cf4bdc1751c65d');
c.put('0xUFCINT_ADDRESS', '0x329feb3ab062d289');

c.put('0xASHESV1_ADDRESS', '0xe2a89587100c6096');
c.put('0xASHESV2_ADDRESS', '0xe2a89587100c6096');
