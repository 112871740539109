import "./config";
import {RecentBurnsTable} from "./components/recentBurns"
import { Row, Col } from "react-bootstrap";
function App() {

  return (
    <div>
      <Row>
        <Col>
        <RecentBurnsTable></RecentBurnsTable>
        </Col>
      </Row>
    </div>
  )
}

export default App;
