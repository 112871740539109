import { useState, useEffect } from "react";
import { getNBACollection, getNBANFTs } from "../lib/flow/nba"
import { Table, Button } from "react-bootstrap"
import ReactPaginate from "react-paginate"

export const NBATable = (props) => {
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => {
    if (props.user !== undefined) {
      setUser(props.user)
    }
  }, [props.user])

  const [nftIDs, setNftIDs] = useState([])
  const [nfts, setNFTs] = useState([])
  const [currentNFTIDs, setCurrentNFTIDs] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [selectedBurn, setSeletecBurn] = useState(new Set())
  const pageSize = 30

  useEffect(() => {
    setCurrentNFTIDs(nftIDs.slice(0, pageSize))
  }, [nftIDs])


  useEffect(() => {
    if (!user.loggedIn) {
      setNftIDs([])
      setTotalCount(0)
      return
    }

    try {
      getNBACollection(user.addr)
      .then((collection) => {
        if (collection) {
          setNftIDs(collection.nftIDs)
          setTotalCount(collection.nftIDs.length)
        }
      })
    } catch (error) {
      console.log(error)
    }

  }, [user, props.onReload])

  useEffect(() => {
    if (!user.loggedIn || currentNFTIDs.length === 0) {
      setNFTs([])
      return
    }

    try {
      getNBANFTs(user.addr, currentNFTIDs)
      .then((nfts) => {
        setNFTs(nfts)
      })
    } catch(error) {
      console.log(error)
    }
  }, [user, currentNFTIDs])

  const tablePageClick = (data) => {
    let currentNFTIDs = nftIDs.slice(data.selected * pageSize, data.selected * pageSize + pageSize)
    setSeletecBurn(new Set())
    setCurrentNFTIDs(currentNFTIDs)
  }


  const onBurnClick = (nftID) => () => {
    props.onBurnClick && props.onBurnClick(nftID)
  }

  const selectAll = () => {
    var checkboxes = document.getElementsByName("cb");
    for (var cb of checkboxes) {
      cb.checked = true
    }
    setSeletecBurn(new Set(nfts && nfts.map(x=>x.id)))
  }

  const onBatchBurnClick = () => {
    props.onBatchBurnClick && props.onBatchBurnClick(Array.from(selectedBurn))
  }

  const onSelectBurn = (nftID) => (e) => {
    let newSet = new Set(selectedBurn)
    if (e.currentTarget.checked) {
      newSet.add(parseInt(nftID))
    } else {
      newSet.delete(parseInt(nftID))
    }
    setSeletecBurn(newSet)
  }

  return (
    <div>
      <h3>
        <p>Moments:  {nftIDs && nftIDs.length}</p>
      </h3>
      {nfts && !!nfts.length && (
        <div>
          <Button variant="info" onClick={selectAll}>Select All</Button> &nbsp;
          {selectedBurn.size != 0 && <Button variant="danger" onClick={onBatchBurnClick}>Burn Selected {selectedBurn.size} moments</Button>}
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>moment id</th>
                <th>set name</th>
                <th>player full name</th>
                <th>serial number</th>
                <th>burn</th>
              </tr>
            </thead>
            <tbody>
              {nfts
                .sort((a, b) => {
                  return a.setName === b.setName ? 0 : +(a.setName > b.setName) || -1
                })
                .map((nft) => {
                  return (
                    <tr key={nft.id}>
                      <td>{nft.id}</td>
                      <td>{nft.setName}</td>
                      <td>{nft.play.FullName}</td>
                      <td>{nft.serialNumber}</td>
                      <td><Button variant="danger" onClick={onBurnClick(nft.id)}>burn</Button> <input name="cb" type="checkbox" onChange={onSelectBurn(nft.id)}/></td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </div>
      )}

      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={<span className="page-link">...</span>}
        breakClassName={"page-item"}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        pageCount={Math.ceil(totalCount / pageSize)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={tablePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />

    </div>
  )

}
