import * as fcl from "@onflow/fcl";
import { useState, useEffect} from "react";
import { burnNBAoAshesV2X } from "../lib/flow/nba"
import { Row, Col } from "react-bootstrap"
import { AshesTable } from "../components/ashes"
import { TXMonitor } from "../components/txmonitor"
import { NBATable } from "../components/nba"
import { BurnModal } from "../components/burnModal"

export const NBA = () => {
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser.subscribe(setUser), []);


  const [transactionId, setTransactionId] = useState(null)

  const [reloadCollection, setReloadCollection] = useState(false)

  const [currentBurnID, setCurrentBurnID] = useState(null)

  const [burnMessage, setBurnMessage] = useState("burn")

  const onBurnClick = (nftID) => {
    setCurrentBurnID([nftID])
    setShow(true)
}

const onBatchBurnClick = (selectedBurn) => {
  console.log("burning: ",selectedBurn)
  setCurrentBurnID(selectedBurn)
  setShow(true)
}

const handleConfirmBurn = () => {
    try {
      burnNBAoAshesV2X(currentBurnID, burnMessage, setTransactionId)
        setCurrentBurnID(null)
    } catch (error) {
        console.log(error)
    }
    setShow(false);
}

const [show, setShow] = useState(false);

const handleClose = () => setShow(false);


  return (
    <div>
      <TXMonitor txID={transactionId} onSuccess={setReloadCollection}></TXMonitor>
      <Row>
        <Col>
          <AshesTable onReload={reloadCollection}></AshesTable>
        </Col>
        <Col>

          <NBATable onReload={reloadCollection} onBurnClick={onBurnClick} onBatchBurnClick={onBatchBurnClick} user={user}></NBATable>
        </Col>
      </Row>
      <BurnModal show={show} handleClose={handleClose} handleConfirmBurn={handleConfirmBurn} burnMessage={burnMessage} setBurnMessage={setBurnMessage}></BurnModal>

    </div>
  )
}