import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";

export const getVanillaAshes = async (address, ashesIDs) => {
  if (ashesIDs && ashesIDs.length === 0) {
    return []
  }

  const resp = await fcl.send([
    fcl.script`
      import TopShot from 0xTOPSHOT_ADDRESS
      import Ashes from 0xASHESV1_ADDRESS
  
  
      pub struct Ash {
        pub var id: UInt64
        pub var momentData: TopShot.MomentData
        pub var play: {String: String}?
        pub var setName: String?
        pub var ashSerial: UInt64

        init(id: UInt64,ashSerial: UInt64, momentData: TopShot.MomentData) {
          self.id = id
          self.momentData = momentData
          self.play = TopShot.getPlayMetaData(playID: momentData.playID)
          self.setName = TopShot.getSetName(setID: momentData.setID)
          self.ashSerial = ashSerial
        }
      }
      pub fun main(ashesIDs: [UInt64]): [Ash] {
      let acct = getAccount(${address})
        var ashes: [Ash] = []

        let collectionRef = acct.getCapability(/public/AshesCollection)!
        .borrow<&{Ashes.AshReceiver}>()!

        for ashID in ashesIDs {
          let ash = collectionRef.borrowAsh(id: ashID)
          if ash != nil{
            ashes.append(Ash(id: ashID, ashSerial: ash!.ashSerial, momentData: ash!.momentData))
          }
        }
        return ashes
    }`,
    fcl.args([fcl.arg(ashesIDs, t.Array(t.UInt64))]),
  ])
  return fcl.decode(resp)
}
  
export const getVanillaAshesCollection = async (address) => {
  try {
    const resp = await fcl.send([
      fcl.script`
      import Ashes from 0xASHESV1_ADDRESS

      pub struct AshesCollection {
        pub var nftIDs: [UInt64]
        init(nftIDs: [UInt64]) {
          self.nftIDs = nftIDs
        }
      }
      pub fun main(): AshesCollection {
        let acct = getAccount(${address})

        var nftIDs: [UInt64] = []
        if let ashesReceiver = acct.getCapability(/public/AshesCollection).borrow<&{Ashes.AshReceiver}>() {
          nftIDs = ashesReceiver.getIDs()
        }
        
        return AshesCollection(nftIDs: nftIDs)
      }`,
      ])
    return fcl.decode(resp)
  } catch (e) {
    console.log(e);
  }
}
  
export const getAshesSummary = async () => {
  try {
    const resp = await fcl.send([
      fcl.script(`
      import Ashes from 0xASHESV1_ADDRESS
      pub struct AshesSummary {
        pub var nextAshSerial: UInt64
        pub var allowMint: Bool
        pub var latestBroadcast: Ashes.Message?
        init(nextAshSerial: UInt64, allowMint: Bool, latestBroadcast: Ashes.Message?) {
          self.nextAshSerial = nextAshSerial
          self.allowMint = allowMint
          self.latestBroadcast = latestBroadcast
        }
      }
      pub fun main(): AshesSummary {
        return AshesSummary(nextAshSerial: Ashes.nextAshSerial, allowMint: Ashes.allowMint, latestBroadcast: Ashes.latestBroadcast)
      }`),
    ])
    return fcl.decode(resp)
  } catch (e) {
    console.log(e);
  }

}

  
export const burnVanillaAshesToAshesV2=async (nftID,message, setTransactionId) => {
  let txID = await fcl.mutate({
    cadence: burnVanillaAshesToAshesV2Script,
    args: (arg, t) => [
      arg([nftID], t.Array(t.UInt64)),
      arg(message, t.String)
    ],
    payer: fcl.authz,
    proposer: fcl.authz,
    authorizations: [fcl.authz],
    limit: 800
  })
  setTransactionId(txID)
}

export const burnVanillaAshesToAshesV2Script = `
      import Ashes from 0xASHESV1_ADDRESS
      import AshesV2 from 0xASHESV2_ADDRESS
    
      transaction(nbaIDs: [UInt64], message: String) {
        let collectionRef: &Ashes.Collection
        let ashesCollection: &AshesV2.Collection
  
        prepare(acct: AuthAccount) {
          self.collectionRef = acct.borrow<&Ashes.Collection>(from: /storage/AshesCollection)!
          
          if acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath) == nil {
            let collection <- AshesV2.createEmptyCollection()
            acct.save(<-collection, to: AshesV2.CollectionStoragePath)
            acct.link<&{AshesV2.AshReceiver}>(AshesV2.CollectionPublicPath, target: AshesV2.CollectionStoragePath)
          } 
          self.ashesCollection = acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath)!
        }

        execute {
            for id in nbaIDs {
                let token <- self.collectionRef.withdraw(withdrawID: id)!
                self.ashesCollection.deposit(token: <- AshesV2.mintFromVanillaAshes(vanillaAshNFT: <- token, msg: message))
            }
  
        }
      }
`

export const burnMoment = async (momentID, setTransactionId) => {

  console.log("burning: ", momentID)

  try {
    const transactionId = await fcl.mutate({
      cadence: burnTSToAshes1Script,
      args: (arg, t) => [arg(momentID, t.UInt64)],
      payer: fcl.authz,
      proposer: fcl.authz,
      authorizations: [fcl.authz],
      limit: 999
    })

    setTransactionId(transactionId)

  } catch (e) {
    console.log(e.toString())
  }

}

const burnTSToAshes1Script = `
    import TopShot from 0xTOPSHOT_ADDRESS
    import Ashes from 0xASHESV1_ADDRESS
  
    transaction(tokenID: UInt64) {
      prepare(acct: AuthAccount) {
    
        let collectionRef = acct.borrow<&TopShot.Collection>(from: /storage/MomentCollection)!
        let token <- collectionRef.withdraw(withdrawID: tokenID) as! @TopShot.NFT
        let ash <- Ashes.mint(topshotNFT: <- token)
        
        if acct.borrow<&Ashes.Collection>(from: /storage/AshesCollection) == nil {
          let collection <- Ashes.createEmptyCollection()
          acct.save(<-collection, to: /storage/AshesCollection)
          acct.link<&{Ashes.AshReceiver}>(/public/AshesCollection, target: /storage/AshesCollection)
        }
  
        let ashesCollection = acct.borrow<&Ashes.Collection>(from: /storage/AshesCollection)!
        ashesCollection.deposit(token: <-ash)
      }
    }
    `