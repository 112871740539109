import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { getUFCCollection, getUFCNFTs } from "../lib/flow/ufc"
import { Table, Button } from "react-bootstrap"
import ReactPaginate from "react-paginate"

export const UFCTable = (props) => {
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser.subscribe(setUser), []);

  const [nftIDs, setNftIDs] = useState([])
  const [nfts, setNFTs] = useState([])
  const [currentNFTIDs, setCurrentNFTIDs] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    setCurrentNFTIDs(nftIDs.slice(0, 2))
  }, [nftIDs])


  useEffect(() => {
    if (!user.addr) {
      return
    }
    getUFCCollection(user.addr)
      .then((collection) => {
        console.log(collection);
        if (collection) {
          setNftIDs(collection.nftIDs)
          setTotalCount(collection.nftIDs.length)
        }
      })
  }, [user, props.onReload])

  useEffect(() => {
    if (!user.addr) {
      return
    }

    getUFCNFTs(user.addr, currentNFTIDs)
      .then((nfts) => {
        console.log(nfts)
        setNFTs(nfts)
      })
  }, [user, currentNFTIDs])


  const tablePageClick = (data) => {
    let currentNFTIDs = nftIDs.slice(data.selected * 2, data.selected * 2 + 2)
    setCurrentNFTIDs(currentNFTIDs)
  }

  const onBurnClick = (nftID) => () => {
    props.onBurnClick && props.onBurnClick(nftID)
  }



  return (
    <div>
      <h3>
        <p>UFC Strike NFTs: {nftIDs && nftIDs.length}</p>
      </h3>
      {nfts && nfts.length && (
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>edition Num</th>
                <th>series name</th>
                <th>athelete name</th>
                <th>preview</th>
                <th>burn</th>
              </tr>
            </thead>
            <tbody>
              {nfts
                .map((nft) => {
                  return (
                    <tr key={nft.id}>
                      <td>{nft.editionNum}</td>
                      <td>{nft.seriesMeta.seriesName}</td>
                      <td>{nft.setMeta["ATHLETE NAME"]}</td>
                      <td><img width="128px" height="128px" src={nft.setMeta.preview} alt={nft.id}></img></td>
                      <td><Button variant="danger" onClick={onBurnClick(nft.id)}>burn</Button></td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>

          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={<span className="page-link">...</span>}
            breakClassName={"page-item"}
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            pageCount={totalCount === 1 ? 1 : totalCount / 2}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={tablePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />


        </div>
      )}
    </div>
  )
}