import { useState } from "react";
import { burnUFCToAshesV2X } from "../lib/flow/ufc"
import { Row, Col } from "react-bootstrap"
import { AshesTable } from "../components/ashes"
import { TXMonitor } from "../components/txmonitor"
import { UFCTable } from "../components/ufc"
export const UFC = () => {

  const [transactionId, setTransactionId] = useState(null)

  const [reloadCollection, setReloadCollection] = useState(false)



  const onBurnClick = (nftID) => {
    console.log(nftID)
    try {
      burnUFCToAshesV2X(nftID, setTransactionId)
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div>
      <TXMonitor txID={transactionId} onSuccess={setReloadCollection}></TXMonitor>
      <Row>
        <Col>
          <AshesTable onReload={reloadCollection}></AshesTable>
        </Col>
        <Col>

          <UFCTable onReload={reloadCollection} onBurnClick={onBurnClick}></UFCTable>
        </Col>
      </Row>

    </div>
  )
}