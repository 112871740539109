import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {TopBar} from './components/nav'
import {FAQ} from './pages/faq'
import {UFC} from './pages/ufc'
import {NFL} from './pages/nfl'
import {NBA} from './pages/nba'

import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import { Container } from 'react-bootstrap';
import { VanillaAshes } from './pages/vanillaAshes';
import TopShot from './pages/TSToAshesV1';
import { Releases } from './pages/releases';

ReactDOM.render(
  <React.StrictMode>
     <BrowserRouter>
     <TopBar/>
     <Container>
     <Routes>
        <Route exact path="/" element={<App/>} />
        <Route exact path="/faq" element={<FAQ/>} />
        <Route exact path="/release" element={<Releases/>} />
        <Route exact path="/ufc" element={<UFC/>} />
        <Route exact path="/nfl" element={<NFL/>} />
        <Route exact path="/nba" element={<NBA/>} />
        <Route exact path="/vanilla" element={<VanillaAshes/>} />
        <Route exact path="/vanillaTS" element={<TopShot/>} />

      </Routes>
     </Container>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
