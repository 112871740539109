import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { Row, Col } from "react-bootstrap"
import { AshesTable } from "../components/ashes"
import { TXMonitor } from "../components/txmonitor"
import { BurnModal } from "../components/burnModal"

import { VanillaAshesTable } from "../components/vanilaAshes";
import { burnVanillaAshesToAshesV2 } from "../lib/flow/vanillaAshes";
export const VanillaAshes = () => {
    const [user, setUser] = useState({ loggedIn: null })
    useEffect(() => fcl.currentUser.subscribe(setUser), [])

    const [transactionId, setTransactionId] = useState(null)

    const [reloadCollection, setReloadCollection] = useState(false)

    const [currentBurnID, setCurrentBurnID] = useState(null)

    const [burnMessage, setBurnMessage] = useState("burn")


    const onBurnClick = (nftID) => {
        console.log(nftID)

        setCurrentBurnID(nftID)
        setShow(true)
    }

    const handleConfirmBurn = () => {
        try {
            burnVanillaAshesToAshesV2(currentBurnID, burnMessage, setTransactionId)
            setCurrentBurnID(null)
        } catch (error) {
            console.log(error)
        }
        setShow(false);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);


    return (
        <div>
            <TXMonitor txID={transactionId} onSuccess={setReloadCollection}></TXMonitor>
            <Row>
                <Col>
                    <AshesTable onReload={reloadCollection}></AshesTable>
                </Col>
                <Col>

                    <VanillaAshesTable user={user} onReload={reloadCollection} onBurnClick={onBurnClick}></VanillaAshesTable>
                </Col>
            </Row>

            <BurnModal show={show} handleClose={handleClose} handleConfirmBurn={handleConfirmBurn} burnMessage={burnMessage} setBurnMessage={setBurnMessage}></BurnModal>

        </div>
    )
}

