export const FAQ = () => {
    return (
        <div>
                          <h3>FAQ</h3>

<ul>
  <li>
  <b>how do I mint ashes from my Dapper Wallet?</b><br/>
You can't for now, you have to withdraw the Topshot Moment into a blocto wallet first in order to burn them into Ashes.
  </li>
  <li>
  <b>what happens to my Topshot NFT when I mint ashes from it?</b><br/>
It's destroyed, gone forever. The Topshot NFT will be marked as destroyed and circulation count adjusted on NBA Topshot as they monitor on chain destroy events.
    </li>
    <li>
  <b>what does message do?</b><br/>
When you burn an NFT supported by ash, you have the ability to send a message along with the burn. This message will be stored on the ashes NFT itself and displayed on the recent burns table. Inappropriate messages will be blacked out on the site however there's no censorship on the contract itself.
    </li>
    <li>
        <b>what is vanilla ash?</b><br/>
Since recent burns was introduced in ashesv2, in order for those "vanilla" ashes to be recorded on recent burns I've added a way for vanilla ash owners (serial 1 - 222) to burn their vanilla ash into ashv2.
    </li>
    <li>
    <b>what can I do with the Ashes NFT?</b><br/>
Nothing. You can't do anything with it beside bragging to your friends about it. The Ashes retain some information from the Topshot moment it came from like set/play/serial.

    </li>
    <li>
    <b>can I see other's Ashes</b><br/>
I'm working on this functionality atm.
    </li>
    <li>
    <b>can I sell my Ashes NFT</b><br/>
The ashes you mint are resource on flow so there's nothing preventing you from listing it on any marketplace that supports it. There are none so far.

    </li>
    <li>
    <b>will this project be maintained</b><br/>
I mostly created this project for learn about FCL, I do plan to open source the project and write up some tutorial blog posts, no guarantees on when that will happen.
    </li>
    <li>
    <b>where is the smart contract?</b><br/>
    <a href="https://flowscan.org/contract/A.e2a89587100c6096.AshesV2">Flowscan link</a>
    </li>
    <li>
    <b>I accidentally burned a legendary moment into ashes</b><br/>
Please accept my sincerest condolences. There's nothing I can do to help you. You can probably brag big time on twitter though.
    </li>          
    <li>
    <b>who are you?</b><br/>
    My name is Eric Ren and I'm a software engineer at DapperLabs. I primarily work on backend so please accept my apologies for this low quality web app. On my free time I like to build random stuff on Flow. <br/>My last project was <a href="https://topshotexplorer.com">topshotexplorer</a>
     <br/>
    <a href="https://twitter.com/rrrkren">Follow me on twitter</a>
    <br/>
    <a href="https://twitter.com/ashesonflow">Follow this project on twitter</a>
    <br/>
    <a href="https://discord.gg/zVYVkDHbjS">Join the project's discord</a>
    <br />
    <a href="https://jobs.lever.co/axiomzen?lever-via=xjeZ7kmgvQ">Join me at DapperLabs</a>
    </li>
    <li>
    <b>I want to support your work</b><br/>
    Feel free to send some flow to the Ashes contract account `0xe2a89587100c6096`. I can't promise you'll get anything in return though.
    </li>

</ul>

        </div>
    )
}