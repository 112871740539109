import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { Row, Col } from "react-bootstrap"
import { burnMoment } from "../lib/flow/vanillaAshes"
import { VanillaAshesTable } from "../components/vanilaAshes"
import { NBATable } from "../components/nba"
import { TXMonitor } from "../components/txmonitor"
import { AshesV1Summary } from "../components/ashesv1summary";

function TopShot() {
  const [user, setUser] = useState({ loggedIn: null })

  const [transactionId, setTransactionId] = useState(null)
  const [reloadMoments, setReloadMoments] = useState(false)



  useEffect(() => fcl.currentUser.subscribe(setUser), [])


  const onBurnClick = (nftID) => {
    try {
      burnMoment(nftID, setTransactionId)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <div>
        <Row>
          <Col>
            <AshesV1Summary onReload={reloadMoments}> </AshesV1Summary>

          </Col>
          <Col>
            Burning instruction:
            <ol>
              <li>Get a Blocto wallet</li>
              <li>Acquire some topshot moments through marketplaces such as blocto bay, or withdraw from your dapper wallet</li>
              <li>Refresh this page, click burn on the topshot moment you'd like to burn</li>
              <li>Approve the transction on your blocto app</li>
            </ol>
          </Col>
        </Row>
        <Row>
          <TXMonitor txID={transactionId} onSuccess={setReloadMoments}></TXMonitor>

        </Row>
        <Row>
          <Col>
            <VanillaAshesTable onReload={reloadMoments} user={user}></VanillaAshesTable>
          </Col>
          <Col>
            <NBATable onReload={reloadMoments} onBurnClick={onBurnClick} user={user}></NBATable>
          </Col>

        </Row>

        <br />

        <div>
        </div>
      </div>
    </div>
  )
}

export default TopShot;
