import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { getAshesCollection, getAsh2s } from "../lib/flow/ashes"
import { Table } from "react-bootstrap"
import ReactPaginate from "react-paginate"


export const metaToDescription = (nftType, metaRaw) => {
  switch (nftType.typeID) {
    case "A.e2a89587100c6096.Ashes.Ash":
      if (metaRaw.tsData) {
        return `${metaRaw.tsData.setName}: ${metaRaw.tsData.play.FullName} #${metaRaw.tsData.serialNumber} `
      }
      return `[topshot] setID: ${metaRaw.vanillaAshTopshotSetID}; topshotID: ${metaRaw.vanillaAshTopshotID}; serial: ${metaRaw.vanillaAshTopshotSerial}; playID: ${metaRaw.vanillaAshTopshotPlayID}`
    case "A.04625c28593d9408.ufcInt_NFT.NFT":
    case "A.329feb3ab062d289.UFC_NFT.NFT":
      return `setID: ${metaRaw.ufcSetID}; ufcID: ${metaRaw.ufcID}; ufcEditionNum: ${metaRaw.ufcEditionNum}`;
    case "A.4dfd62c88d1b6462.AllDay.NFT":
      return `serial: ${metaRaw.alldaySerialNumber}; editionID: ${metaRaw.alldayEditionID}; allDayID: ${metaRaw.alldayID}`;
    case "A.877931736ee77cff.TopShot.NFT":
    case "A.0b2a3299cc857e29.TopShot.NFT":
      if (metaRaw.tsData) {
        return `${metaRaw.tsData.setName}: ${metaRaw.tsData.play.FullName} #${metaRaw.tsData.serialNumber} `
      }
      return `setID: ${metaRaw.topshotSetID}; topshotID: ${metaRaw.topshotID}; serial: ${metaRaw.topshotSerial}; playID: ${metaRaw.topshotPlayID}`
    default:
      return JSON.stringify(metaRaw)

  }
}

export const AshesTable = (props) => {
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser.subscribe(setUser), []);

  const [ash2s, setAsh2s] = useState([])
  const [ash2IDs, setAsh2IDs] = useState([])
  const [currentAsh2IDs, setCurrentAsh2IDs] = useState([])

  const [totalCount, setTotalCount] = useState(0)
  const pageSize = 8
  // const [pageSize, setPageSize] = useState(8)

  useEffect(() => {
    setCurrentAsh2IDs(ash2IDs.slice(0, pageSize))
  }, [ash2IDs])


  useEffect(() => {
    if (!user.addr) {
      return
    }
    try {
      getAsh2s(user.addr, currentAsh2IDs)
      .then((ash2s) => {
        setAsh2s(ash2s)
      })

    } catch(error) {
      console.log(error)
    }

  }, [user, currentAsh2IDs])

  useEffect(() => {
    if (!user.addr) {
      return
    }

    try {
      getAshesCollection(user.addr)
      .then((collection) => {
        console.log(collection);
        if (collection) {
          setAsh2IDs(collection.ashesIDs)
          setTotalCount(collection.ashesIDs.length)
        }
      })

    } catch(error) {
      console.log(error)
    }
  }, [user, props.onReload])


  const tablePageClick = (data) => {
    let currentAsh2IDs = ash2IDs.slice(data.selected * pageSize, data.selected * pageSize + pageSize)
    setCurrentAsh2IDs(currentAsh2IDs)
  }


  return (
    <div>

      <h3>
        <p>My Ashes: {totalCount}</p>
      </h3>
      {totalCount && (
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>ashSerial</th>
                <th>burned NFT type</th>
                <th>burned NFT ID</th>
                <th>message</th>
                <th>metaRaw</th>
              </tr>
            </thead>
            <tbody>
              {ash2s
                .map((ash2) => {
                  return (
                    <tr key={ash2.id}>
                      <td>{ash2.ashData.ashSerial}</td>
                      <td>{ash2.ashData.nftType.typeID}</td>
                      <td>{ash2.ashData.nftID}</td>
                      <td style={{wordBreak: 'break-all'}}>{ash2.ashData.meta["_message"]}</td>
                      <td style={{wordBreak: 'break-all', minWidth: '210px'}}>{metaToDescription(ash2.ashData.nftType, ash2.ashData.meta)}</td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>

          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={<span className="page-link">...</span>}
            breakClassName={"page-item"}
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            pageCount={Math.ceil(totalCount / pageSize)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={tablePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />

        </div>
      )}

    </div>
  )
}