import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types"

export const getUFCCollection = async (address) => {
  const resp = await fcl.send([
    fcl.script(getUFCCollectionScript), 
    fcl.args([fcl.arg(address, t.Address)])
  ])
  return fcl.decode(resp)
}

export const getUFCNFTs = async (address, nftIDs) => {
  if (!nftIDs || (nftIDs && nftIDs.length === 0)) {
    return []
  }
  const resp = await fcl.send([
    fcl.script(getUFCNFTsScript),
    fcl.args([fcl.arg(nftIDs, t.Array(t.UInt64)), fcl.arg(address, t.Address)]),
  ])
  return fcl.decode(resp)
}


export const burnUFCToAshesV2X = async (ufcID, setTransactionId) => {
  let txID = await fcl.mutate({
    cadence: burnUFCToAshes2Script,
    args: (arg, t) => [
      arg([ufcID], t.Array(t.UInt64)),
      arg("woah", t.String)
    ],
    payer: fcl.authz,
    proposer: fcl.authz,
    authorizations: [fcl.authz],
    limit: 800
  })
  setTransactionId(txID)
}

const getUFCCollectionScript = `
import UFC_NFT from 0xUFCINT_ADDRESS

pub struct UFCCollection {
  pub var nftIDs: [UInt64]

  init(nftIDs: [UInt64]) {
    self.nftIDs = nftIDs
  }
}
pub fun main(acctAddr: Address): UFCCollection {
  let acct = getAccount(acctAddr)
  var nftIDs: [UInt64] = []
  if let collectionRef = acct.getCapability(UFC_NFT.CollectionPublicPath).borrow<&{UFC_NFT.UFC_NFTCollectionPublic}>(){
    nftIDs = collectionRef.getIDs()
  }

    
  return UFCCollection(nftIDs: nftIDs)
}`

const getUFCNFTsScript = `
import UFC_NFT from 0xUFCINT_ADDRESS
pub struct ufcIntNFT {
  pub let id: UInt64
  pub let setId: UInt32
  pub let editionNum: UInt32
  pub let seriesId: UInt32

  pub let setMeta: {String: String}
  pub let seriesMeta: {String: String}

  init(nft: &UFC_NFT.NFT) {
    self.id = nft.id
    self.editionNum = nft.editionNum

    self.setId = nft.setId
    self.setMeta = UFC_NFT.getSetMetadata(setId: self.setId)!

    self.seriesId = UFC_NFT.getSetSeriesId(setId: self.setId)!
    self.seriesMeta = UFC_NFT.getSeriesMetadata(seriesId: self.seriesId)!
  }
}
pub fun main(nftIDs: [UInt64], acctAddr: Address): [ufcIntNFT] {
let acct = getAccount(acctAddr)
let collectionRef = acct.getCapability(UFC_NFT.CollectionPublicPath)!
              .borrow<&{UFC_NFT.UFC_NFTCollectionPublic}>()!
  var nfts: [ufcIntNFT] = []
  for nftID in nftIDs {
    let nft = collectionRef.borrowUFC_NFT(id: nftID)!
    if nft != nil{
      nfts.append(ufcIntNFT(nft: nft))
    }
  }
  return nfts
}  `

const burnUFCToAshes2Script = `import AshesV2 from 0xASHESV2_ADDRESS
    import UFC_NFT from 0xUFCINT_ADDRESS
  
  transaction(ufcIDs: [UInt64], message: String) {
      let ufcCollection: &UFC_NFT.Collection
      let ashesCollection: &AshesV2.Collection
  
      prepare(acct: AuthAccount) {
        self.ufcCollection = acct.borrow<&UFC_NFT.Collection>(from: UFC_NFT.CollectionStoragePath)!
  
        if acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath) == nil {
          let collection <- AshesV2.createEmptyCollection()
          acct.save(<-collection, to: AshesV2.CollectionStoragePath)
          acct.link<&{AshesV2.AshReceiver}>(AshesV2.CollectionPublicPath, target: AshesV2.CollectionStoragePath)
        } 
        self.ashesCollection = acct.borrow<&AshesV2.Collection>(from: AshesV2.CollectionStoragePath)!
      }
  
      execute {
          for id in ufcIDs {
              let ufcNFT <- self.ufcCollection.withdraw(withdrawID: id) as! @UFC_NFT.NFT
              self.ashesCollection.deposit(token: <- AshesV2.mintFromUFCStrike(ufcNFT: <- ufcNFT, msg: message))
          }
      }
  }`

